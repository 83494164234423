import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import camelize from 'camelize';
import {
  INIT_APP,
  SAVE_ANSWER, SAVE_ANSWER_SUCCEEDED,
  GET_INFO_REQUESTED, GET_INFO_SUCCEEDED, GET_INFO_FAILED,
  FETCH_QUESTION_REQUESTED, FETCH_QUESTION_SUCCEEDED, FETCH_QUESTION_FAILED,
  GET_STATUS_REQUESTED, GET_STATUS_SUCCEEDED, GET_STATUS_FAILED,
  SUBMIT_AREA_ANSWER_REQUESTED, SUBMIT_AREA_ANSWER_SUCCEEDED, SUBMIT_AREA_ANSWER_FAILED,
  END_EXAM_REQUESTED, END_EXAM_SUCCEEDED, END_EXAM_FAILED,
  PASS_ANSWER_REQUESTED, PASS_ANSWER_SUCCEEDED, PASS_ANSWER_FAILED,
  PRACTICE_ANSWER_REQUESTED, PRACTICE_ANSWER_SUCCEEDED, PRACTICE_ANSWER_FAILED,
  GET_TIME_LEFT_REQUESTED, GET_TIME_LEFT_SUCCEEDED, GET_TIME_LEFT_FAILED,

  getInfo as mkGetInfo,
  getQuestionStatus as mkGetQuestionStatus,
  fetchQuestion as mkFetchQuestion,
  submitAreaAnswer as mkSubmitAreaAnswer,
  endPauseExam as mkendPauseExam,
  getTimeLeft as mkGetTimeLeft,
  getCerebry_practice_detail as mkgetCerebry_practice_detail,
  POST_REVIEW_REQUESTED, POST_REVIEW_FAILED, POST_REVIEW_SUCCEEDED,
  GET_SCORE_CARD_DETAILS_SUCCEEDED,
  GET_SCORE_CARD_DETAILS_FAILED,
  GET_SELECTED_QUESTION_DETAILS_SUCCEEDED,
  GET_SELECTED_QUESTION_DETAILS_FAILED,
  GET_SCORE_CARD_DETAILS_REQUESTED,
  GET_SELECTED_QUESTION_DETAILS_REQUESTED,
  scoreCardDetails,
  INIT_SCORE_CARD_APP,
  FETCH_FILTERED_QUESTION_REQUESTED,
  FETCH_FILTERED_QUESTION_SUCCEEDED,
  FETCH_FILTERED_QUESTION_FAILED,
  SET_FILTERS,
  PING,
  ADD_NEW_TIME_AFTER_ACTIVE,
  VDO_CIPHER_DETAIL_FAILED,
  VDO_CIPHER_DETAIL_SUCCESS,
  VDO_CIPHER,
  CEREBRY_PRACTICE_DETAIL,
  CEREBRY_PRACTICE_DETAIL_FAILED,
  CEREBRY_PRACTICE_DETAIL_SUCCESS,
} from './action';
import API from './api';
import {
  mkSelectQuestionList,
  mkSelectMode,
  mkSelectTestId,
  mkSelectQues,
  mkSelectQuesView,
  mkSelectFilteredQuestionList,
} from './selector';

import { useHistory } from 'react-router-dom';
import { parseQuestionResponse, b64DecodeUnicode } from './utils';

const JWT_TOKEN = 'test_engine_auth_token';
const JWT_REFRESH = 'cerebry-jwt-refresh';

const RESET_PAGE = '/reset';
const CONTENT_PAGE = '/content';

const getSavedJWT = (jwtKey) => {
  return Cookies.get(jwtKey);
};

const getPathName = (key) => {
  return location.pathname.indexOf(key) > -1 ? true : false;
};

const saveJWT = (jwtKey, jwtValue) => {
  if (location.hostname === 'localhost') {
    Cookies.set(jwtKey, jwtValue);
  } else {
    Cookies.set(jwtKey, jwtValue, { expires: 30, domain: 'imsindia.com' });
  }
};

function toLocaleISOString(date) {
  function pad(n) {
    return ('0' + n).substr(-2);
  }

  var day = [
    date.getFullYear(),
    pad(date.getMonth() + 1),
    pad(date.getDate()),
  ].join('-'),
    time = [date.getHours(), date.getMinutes(), date.getSeconds()]
      .map(pad)
      .join(':');
  if (date.getMilliseconds()) time += '.' + date.getMilliseconds();
  var o = date.getTimezoneOffset(),
    h = Math.floor(Math.abs(o) / 60),
    m = Math.abs(o) % 60,
    o = o == 0 ? 'Z' : (o < 0 ? '+' : '-') + pad(h) + ':' + pad(m);
  return day + 'T' + time + o;
}

export function removeJWT(jwtKey) {
  Cookies.remove(jwtKey);
}

function* initApp(dispatch) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const auth_tok = urlParams.get("auth_tok");
    const test_id = urlParams.get("test_id");
    if (auth_tok) {
      saveJWT(JWT_TOKEN, auth_tok);
      saveJWT(JWT_REFRESH, '');
    }
    const jwtToken = getSavedJWT(JWT_TOKEN);
    API.setAccessToken('jwt ' + jwtToken);
    yield put(mkGetInfo(test_id))
  } catch (e) {
    console.log(e.message);
  }
}

function* getInfo(action) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "?cerebry_practice=true" : ""
    const { testId } = action
    const resp = yield call(API.getInfo, testId, cerebry_query)

    // if (resp.status < 400) {
    //   console.log("check-->",resp.data)
    //   if (resp.data.time_left == 0) {
    //     console.log('saga --> ',resp.data.time_left)
    //      yield put(mkendPauseExam(0, 1))
    //    }
    // }
    if (resp.data?.filters) {
      const questionsList = resp.data.filters.map(obj => Object.values(obj)).flat(2).map((_, index) => ({ ..._, rank: index }))
      const questionCategories = Object.assign({}, ...Object.entries({ ...resp.data.filters }).map(([a, b]) => b))
      if (resp.data.hide_scorecard && Object.keys(resp.data.area_topic_filters).length > 0) {
        Object.keys(resp.data?.area_topic_filters).forEach(category => {
          resp.data?.area_topic_filters[category].areas.unshift("All");
          resp.data?.area_topic_filters[category].topics.unshift("All");
        });
      }

      resp.data.questionCategories = questionCategories;
      resp.data.questionList = questionsList


      // pushing filters to redux
      yield put({
        type: SET_FILTERS,
        key: "categories",
        data: [Object.keys(questionCategories)[0]]
      })

      yield put({
        type: SET_FILTERS,
        key: "status",
        data: { label: "All", value: "all" }
      })

      if (resp.data.hide_scorecard && Object.keys(resp.data.area_topic_filters).length > 0) {
        yield put({
          type: SET_FILTERS,
          key: "areas",
          data: resp.data?.area_topic_filters[Object.keys(questionCategories)[0]].areas[0]
        })
  
        yield put({
          type: SET_FILTERS,
          key: "topics",
          data: resp.data?.area_topic_filters[Object.keys(questionCategories)[0]].topics[0]
        })
      }
    }

    // check for not camelizing certain values of the object where "-" is coming in names.
    let resp_data = {};
    for (let key in resp.data) {
      if (key == "questionCategories") {
        let newQuestionCategories = {};
        for (let key1 in resp.data.questionCategories) {
          newQuestionCategories[key1] = camelize(resp.data.questionCategories[key1]);
        }
        resp_data[key] = newQuestionCategories;
      } else if (key == "name") {
        resp_data[camelize(key)] = resp.data[key];
      } else {
        resp_data[camelize(key)] = camelize(resp.data[key]);
      }
    }

    if (resp.status < 400) {
      // if (cerebry_query == "") {
        if (cerebry_query == "" || cerebry_query=="?cerebry_practice=true") {
        yield put(mkGetTimeLeft(testId))
        yield put({
          type: GET_INFO_SUCCEEDED,
          data: resp_data,
          testId
        })

        if (resp.data.assessment_status < 2 && resp.data.last_attempted_question) {
          let qIndex = 0
          resp.data.question_list.forEach((qObj, key) => {
            if (qObj.id == resp.data.last_attempted_question) qIndex = key
          })

          yield put(mkFetchQuestion(qIndex, testId))

          // if (resp.data.time_left == 0) {
          //  console.log('saga --> ',resp.data.time_left)
          //   yield put(mkendPauseExam(0, 1))
          // } else {
          //   yield put(mkFetchQuestion(qIndex, testId))
          // }

        }
      } else {
        yield put({
          type: GET_INFO_SUCCEEDED,
          data: resp_data,
          testId
        })
        yield put(mkFetchQuestion(0, testId))

      }


      // if (resp.data?.filters) { 
      //   yield put({
      //     type: SET_FILTERS,
      //     key: "categories",
      //     data: [Object.keys(questionCategories)[0]]
      //   })
      // }
    }
    else {
      yield put({
        type: GET_INFO_FAILED,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: GET_INFO_FAILED,
      message: e.message
    })
  }
}

function* fetchQuestion(action) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "&cerebry_practice=true" : ""
    const area_cerebry_query = urlParams.get("cerebry_practice") == "true" ? "?cerebry_practice=true" : ""
    const { qIndex, testId } = action
    const quesList = yield select(mkSelectQuestionList())
    const modeType = yield select(mkSelectMode())
    const qId = quesList[qIndex].id
    const resp = yield call(modeType == 1 ? API.getAreaQuestion : API.getPracticeQuestion, qId, cerebry_query, testId ,area_cerebry_query)
    if (resp.status < 400) {
      resp.data.question.question = b64DecodeUnicode(resp.data.question.question)
      if (resp.data.question.instructions) resp.data.question.instructions = b64DecodeUnicode(resp.data.question.instructions)
      if (resp.data.stats) resp.data.stats = b64DecodeUnicode(resp.data.stats)
      if (resp.data.benchmark) resp.data.benchmark = b64DecodeUnicode(resp.data.benchmark)
      if (resp.data.revise_modal) resp.data.revise_modal = b64DecodeUnicode(resp.data.revise_modal)
      if (resp.data.question.solution) resp.data.question.solution = b64DecodeUnicode(resp.data.question.solution)
      if (resp.data.question.answer_type) resp.data.question.answer_type = Array.isArray(resp.data.question.answer_type) ?
        resp.data.question.answer_type.map((answerObj) => b64DecodeUnicode(answerObj)) :
        b64DecodeUnicode(resp.data.question.answer_type)

     if(modeType == 1){
      if(resp.data.time_left == 0){
        window.location.reload()
      }else{
        yield put({
          type: ADD_NEW_TIME_AFTER_ACTIVE,
          data: resp.data.time_left
        })
      }
     }
     
      yield put({
        type: FETCH_QUESTION_SUCCEEDED,
        data: camelize(resp.data),
        qId,
        qIndex
      })
      
      if(resp.data?.cerebry_topic_id && resp.data?.cerebry_topic_id !=null){
        yield put (mkgetCerebry_practice_detail(resp.data?.cerebry_topic_id))
      }

      if ([1, 2].includes(modeType)) yield put(mkGetQuestionStatus())
    }
    else {
      yield put({
        type: FETCH_QUESTION_FAILED,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: FETCH_QUESTION_FAILED,
      message: e.message
    })
  }
}

function* fetchFilteredQuestion(action) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "&cerebry_practice=true" : ""
    const area_cerebry_query = urlParams.get("cerebry_practice") == "true" ? "?cerebry_practice=true" : ""
    const { qIndex, testId } = action
    const quesList = yield select(mkSelectFilteredQuestionList())
    const modeType = yield select(mkSelectMode())
    const qId = quesList[qIndex].id
    const resp = yield call(modeType == 0 ? API.getPracticeQuestion : API.getAreaQuestion, qId, cerebry_query, testId, area_cerebry_query)
    if (resp.status < 400) {
      resp.data.question.question = b64DecodeUnicode(resp.data.question.question)
      if (resp.data.question.instructions) resp.data.question.instructions = b64DecodeUnicode(resp.data.question.instructions)
      if (resp.data.stats) resp.data.stats = b64DecodeUnicode(resp.data.stats)
      if (resp.data.question.solution) resp.data.question.solution = b64DecodeUnicode(resp.data.question.solution)
      if (resp.data.question.answer_type) resp.data.question.answer_type = Array.isArray(resp.data.question.answer_type) ?
        resp.data.question.answer_type.map((answerObj) => b64DecodeUnicode(answerObj)) :
        b64DecodeUnicode(resp.data.question.answer_type)
      yield put({
        type: FETCH_FILTERED_QUESTION_SUCCEEDED,
        data: camelize(resp.data),
        qId,
        qIndex
      })

      console.log("respresp",resp)
      if(resp.data?.cerebry_topic_id && resp.data?.cerebry_topic_id !=null){
        yield put (mkgetCerebry_practice_detail(resp.data?.cerebry_topic_id))
      }

      if (modeType == 1) yield put(mkGetQuestionStatus())
    }
    else {
      yield put({
        type: FETCH_FILTERED_QUESTION_FAILED,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: FETCH_FILTERED_QUESTION_FAILED,
      message: e.message
    })
  }
}

function* saveAnswer(action) {
  try {
    const { ans, qId, review } = action
    const questionView = yield select(mkSelectQuesView())
    const usrAns = ans == questionView[qId].userResponse
      ? null
      : ans
    yield put({
      type: SAVE_ANSWER_SUCCEEDED,
      qId,
      ans: usrAns
    })
    // yield put(mkSubmitAreaAnswer(review))
  } catch (e) {

  }
}

function* getQuestionStatus(action) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "?cerebry_practice=true" : ""

    const testId = yield select(mkSelectTestId())
    const modeType = yield select(mkSelectMode())
    const resp = yield call(modeType == 1 ? API.getAreaStatus : API.getPracticeStatus, testId, cerebry_query)
    if (resp.status < 400) {
      yield put({
        type: GET_STATUS_SUCCEEDED,
        data: camelize(resp.data),
      })
    }
    else {
      yield put({
        type: GET_STATUS_FAILED,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: GET_STATUS_FAILED,
      message: e.message
    })
  }
}

function* submitAreaNext(action) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "?cerebry_practice=true" : ""

    const selectedQues = yield select(mkSelectQues())
    const quesView = yield select(mkSelectQuesView())
    // const quesList = yield select(mkSelectQuestionList())
    const payload = {}
    payload.question_id = selectedQues.id
    // payload.user_response = Array.isArray(quesView[selectedQues.id].question.answerType) ? `[[${quesView[selectedQues.id].userResponse}]]` : `[[${parse_answer.extractAnswers()}]]`;
    payload.user_response = Array.isArray(quesView[selectedQues.id].question.answerType) ? `[[${quesView[selectedQues.id].userResponse}]]` : `[[${parse_answer.extractAnswers()}]]`;
    payload.review = action.review
    // payload.cross_out_options_list = quesView[selectedQues.id].crossOutOptionsList
    // const resp = yield call(API.submitAreaNext, payload)
    const resp = yield call(API.submitAreaNext, payload,cerebry_query)
    if (resp.status < 400) {
      yield put({
        type: SUBMIT_AREA_ANSWER_SUCCEEDED,
        data: camelize(resp.data),
      })
      // const nextIndex = quesList.length > selectedQues.qIndex+1
      //   ? selectedQues.qIndex+1
      //   : 0
      // yield put(mkFetchQuestion(nextIndex));
    }
    else {
      yield put({
        type: SUBMIT_AREA_ANSWER_FAILED,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: SUBMIT_AREA_ANSWER_FAILED,
      message: e.message
    })
  }
}

function* endPauseExam(action) {
  try {
    
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "?cerebry_practice=true" : ""
    const cerebry_query_params = urlParams.get("cerebry_practice") == "true" ? "&cerebry_practice=true" : ""

    const testId = yield select(mkSelectTestId())
    const modeType = yield select(mkSelectMode())
    const payload = {}
    payload.test_id = testId
    payload.completion_type = action.endTest
    payload.time_left = action.timeLeft
    const resp = yield call(modeType == 0 ? API.endPracticePauseExam : API.endPauseExam, payload,cerebry_query)
    if (resp.status < 400) {
      yield put({
        type: END_EXAM_SUCCEEDED,
        data: camelize(resp.data),
      })
      // if (action.endTest != 0) location.href = `/score-card?test_id=${testId}`
      if (action.endTest != 0) location.href = `/score-card?test_id=${testId}${cerebry_query_params}`
    }
    else {
      yield put({
        type: END_EXAM_FAILED,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: END_EXAM_FAILED,
      message: e.message
    })
  }
}

function* passAnswer(action) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "?cerebry_practice=true" : ""

    const selectedQues = yield select(mkSelectQues())
    const payload = {}
    payload.question_id = selectedQues.id
    payload.user_response = null
    payload.cross_out_options_list = []
    payload.time_elapsed = action.timeElapsed
    // const resp = yield call(API.submitPractice, payload)
    const resp = yield call(API.submitPractice, payload,cerebry_query)
    if (resp.status < 400) {
      yield put({
        type: PASS_ANSWER_SUCCEEDED,
        data: camelize(resp.data),
        qId: selectedQues.id,
      })
      yield put(mkGetQuestionStatus())
    }
    else {
      yield put({
        type: PASS_ANSWER_FAILED,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: PASS_ANSWER_FAILED,
      message: e.message
    })
  }
}

function* submitPracticeAnswer(action) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "?cerebry_practice=true" : ""

    const selectedQues = yield select(mkSelectQues())
    const quesView = yield select(mkSelectQuesView())
    const payload = {}
    payload.question_id = selectedQues.id
    payload.user_response = Array.isArray(quesView[selectedQues.id].question.answerType) ? `[[${quesView[selectedQues.id].userResponse}]]` : parse_answer.extractAnswers();
    payload.cross_out_options_list = quesView[selectedQues.id].crossOutOptionsList
    payload.time_elapsed = action.timeElapsed
    // const resp = yield call(API.submitPractice, payload)
    // console.log("payload",`[["${quesView[selectedQues.id].userResponse}"]]`, parse_answer.extractAnswers())
    const resp = yield call(API.submitPractice, payload,cerebry_query)
    if (resp.status < 400) {
      // resp.data.message = "You failed to get the right answer for this question"
      yield put({
        type: PRACTICE_ANSWER_SUCCEEDED,
        data: camelize(resp.data),
        qId: selectedQues.id,
      })
      yield put(mkGetQuestionStatus())
    }
    else {
      yield put({
        type: PRACTICE_ANSWER_FAILED,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: PRACTICE_ANSWER_FAILED,
      message: e.message
    })
  }
}

function* getTimeLeft(action) {
  try {
    // const resp = yield call(API.getTimeLeft, action.testId)
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "?cerebry_practice=true" : ""
    const resp = yield call(API.getTimeLeft, action.testId,cerebry_query)
    if (resp.status < 400) {
      yield put({
        type: GET_TIME_LEFT_SUCCEEDED,
        data: camelize(resp.data),
      })
      yield put(mkGetQuestionStatus())
    }
    else {
      yield put({
        type: GET_TIME_LEFT_FAILED,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: GET_TIME_LEFT_FAILED,
      message: e.message
    })
  }
}

function* postReview(action) {
  try {
    const selectedQues = yield select(mkSelectQues())
    const quesView = yield select(mkSelectQuesView())

    const { reviewQues, qId } = action
    const payload = {}
    payload.question_id = qId
    payload.review = reviewQues
    // payload.user_response = Array.isArray(quesView[selectedQues.id].question.answerType) ? `[[${quesView[selectedQues.id].userResponse}]]` : `[[${parse_answer.extractAnswers()}]]`;
    payload.user_response = Array.isArray(quesView[selectedQues.id].question.answerType) ? `[[${quesView[selectedQues.id].userResponse}]]` : `[[${parse_answer.extractAnswers()}]]`;
    // payload.cross_out_options_list = quesView[selectedQues.id].crossOutOptionsList
    const resp = yield call(API.submitAreaNext, payload)
    if (resp.status < 400) {
      yield put({
        type: POST_REVIEW_SUCCEEDED,
        data: camelize(resp.data),
      })
      // const nextIndex = quesList.length > selectedQues.qIndex+1
      //   ? selectedQues.qIndex+1
      //   : 0
      // yield put(mkFetchQuestion(nextIndex));
    }
    else {
      yield put({
        type: POST_REVIEW_FAILED,
        resp
      })
    }
  } catch (e) {
    yield put({
      type: POST_REVIEW_FAILED,
      message: e.message
    })
  }
}

function* initScoreCardApp(dispatch) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const auth_tok = urlParams.get("auth_tok");
    const test_id = urlParams.get("test_id");
    if (auth_tok) {
      saveJWT(JWT_TOKEN, auth_tok);
      saveJWT(JWT_REFRESH, "");
    }
    const jwtToken = getSavedJWT(JWT_TOKEN);
    API.setAccessToken("jwt " + jwtToken);
    yield put(scoreCardDetails(test_id));
  } catch (e) {
    console.log(e.message);
  }
}


function* getScoreCardDetails(action) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "?cerebry_practice=true" : ""
    
    const testId = action.testid;
    // const resp = yield call(API.getScoreCardDetails, testId);
    const resp = yield call(API.getScoreCardDetails, testId,cerebry_query);
    if (resp.status < 400) {
      yield put({
        type: GET_SCORE_CARD_DETAILS_SUCCEEDED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: GET_SCORE_CARD_DETAILS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_SCORE_CARD_DETAILS_FAILED,
      message: e.message,
    });
  }
}

function* getSelectedQuestionDetails(action) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cerebry_query = urlParams.get("cerebry_practice") == "true" ? "&cerebry_practice=true" : ""


    const questionId = action.qid;
    const testId = action.testId
    // const resp = yield call(API.getSelectedQuestionDetails, questionId, testId);
    const resp = yield call(API.getSelectedQuestionDetails, questionId, testId,cerebry_query);
    if (resp.status < 400) {
      resp.data.question = b64DecodeUnicode(resp.data.question);
      if (resp.data.solution)
        resp.data.solution = b64DecodeUnicode(resp.data.solution);
      yield put({
        type: GET_SELECTED_QUESTION_DETAILS_SUCCEEDED,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: GET_SELECTED_QUESTION_DETAILS_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: GET_SELECTED_QUESTION_DETAILS_FAILED,
      message: e.message,
    });
  }
}

function* getPingAPI(action) {
  try {
    const testId = action.testId
    const resp = yield call(API.getPingAPI, testId);
  } catch (err) {
    console.log("error", err)
  }
}



function* getVdoCipherDetail(action) {
  try {
    const cipherId = action.id;
    const payload = {
      vdo_cipher_id: cipherId
  }
    const resp = yield call(API.getCipherDetails,payload);
    if (resp.status < 400) {
      yield put({
        type: VDO_CIPHER_DETAIL_SUCCESS,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: VDO_CIPHER_DETAIL_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: VDO_CIPHER_DETAIL_FAILED,
      message: e.message,
    });
  }
}

function* getCerebryPractiseDetails(action) {
  try {
    const practiseId = action.id;
    const payload = {
      cerebry_topic_id: practiseId
  }
    const resp = yield call(API.getCerebryPractiseDetails,payload);
    console.log("resphi",resp)
    if (resp.status < 400) {
      yield put({
        type: CEREBRY_PRACTICE_DETAIL_SUCCESS,
        data: camelize(resp.data),
      });
    } else {
      yield put({
        type: CEREBRY_PRACTICE_DETAIL_FAILED,
        resp,
      });
    }
  } catch (e) {
    yield put({
      type: CEREBRY_PRACTICE_DETAIL_FAILED,
      message: e.message,
    });
  }
}




export default function* rootSaga() {
  yield all([
    takeLatest(INIT_APP, initApp),
    takeLatest(SAVE_ANSWER, saveAnswer),
    takeLatest(GET_INFO_REQUESTED, getInfo),
    takeLatest(FETCH_QUESTION_REQUESTED, fetchQuestion),
    takeLatest(FETCH_FILTERED_QUESTION_REQUESTED, fetchFilteredQuestion),
    takeLatest(GET_STATUS_REQUESTED, getQuestionStatus),
    takeLatest(SUBMIT_AREA_ANSWER_REQUESTED, submitAreaNext),
    takeLatest(END_EXAM_REQUESTED, endPauseExam),
    takeLatest(PASS_ANSWER_REQUESTED, passAnswer),
    takeLatest(PRACTICE_ANSWER_REQUESTED, submitPracticeAnswer),
    takeLatest(GET_TIME_LEFT_REQUESTED, getTimeLeft),
    takeLatest(POST_REVIEW_REQUESTED, postReview),
    takeLatest(INIT_SCORE_CARD_APP, initScoreCardApp),
    takeLatest(GET_SCORE_CARD_DETAILS_REQUESTED, getScoreCardDetails),
    takeLatest(VDO_CIPHER, getVdoCipherDetail),
    takeLatest(CEREBRY_PRACTICE_DETAIL, getCerebryPractiseDetails),
    takeLatest(
      GET_SELECTED_QUESTION_DETAILS_REQUESTED,
      getSelectedQuestionDetails
    ),
    takeLatest(PING, getPingAPI),
  ]);
}
