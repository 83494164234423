import { h } from 'preact';
import store from '../store';
import { BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';
import { useState,useEffect } from 'preact/hooks';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { initApp, initScoreCardApp } from "../action"
import PrivateRoute from "./PrivateRoute"
import Main from "../routes/Main"
import { Route } from "preact-router"
import style from "./style.scss"
import ScoreCard from "../routes/ScoreCard";
import PlayReview from '../routes/PlayReview';
import PlayReviewFull from '../routes/PlayReviewFull';
import QuestionReview from '../routes/QuestionReview';

const AppWrapper = () => {
  const dispatch = useDispatch();
  const userSession = useSelector((state) => state.userSession);
};

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <div id='app' className={style['td--body']}>
          <AppWrapper />
          <Switch>
            <PrivateRoute path='/' exact component={Main} />
            <PrivateRoute path="/score-card" component={ScoreCard} />
            <PrivateRoute path="/review" component={PlayReview} />
            <PrivateRoute path="/full-review" component={PlayReviewFull} />
            <PrivateRoute path="/question-review" component={QuestionReview} />

          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
