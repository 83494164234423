import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";

/**
 * This component checks that user is eligible for visiting the private routes or not
 * check the condition for the for showing private route
 * if user is not eligible for visiting the private route then redirect it to the other route (login)
 */

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userSession } = useSelector((state) => state);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Route
        {...rest}
        render={(props) => <Component {...props} />
        }
      />
    </Suspense>
  );
};

export default PrivateRoute;
