import './style';
import App from './components/app';

function loadStylesheet(url) {
  var link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = url;
  document.head.appendChild(link);
}

loadStylesheet(
  'https://cdn.jsdelivr.net/npm/katex@0.12.0/dist/katex.min.css',
);

export default App;
