export const INIT_APP = 'INIT_APP';

export const GET_INFO_REQUESTED = "GET_INFO_REQUESTED"
export const GET_INFO_SUCCEEDED = "GET_INFO_SUCCEEDED"
export const GET_INFO_FAILED = "GET_INFO_FAILED"

export const FETCH_QUESTION_REQUESTED = "FETCH_QUESTION_REQUESTED"
export const FETCH_QUESTION_SUCCEEDED = "FETCH_QUESTION_SUCCEEDED"
export const FETCH_QUESTION_FAILED = "FETCH_QUESTION_FAILED"

export const GET_STATUS_REQUESTED = "GET_STATUS_REQUESTED"
export const GET_STATUS_SUCCEEDED = "GET_STATUS_SUCCEEDED"
export const GET_STATUS_FAILED = "GET_STATUS_FAILED"

export const SAVE_ANSWER = "SAVE_ANSWER"
export const SAVE_ANSWER_SUCCEEDED = "SAVE_ANSWER_SUCCEEDED"
export const SAVE_CROSSOUT = "SAVE_CROSSOUT"
export const SAVE_PRACTICE_ANSWER = "SAVE_PRACTICE_ANSWER"

export const SUBMIT_AREA_ANSWER_REQUESTED = "SUBMIT_AREA_ANSWER_REQUESTED"
export const SUBMIT_AREA_ANSWER_SUCCEEDED = "SUBMIT_AREA_ANSWER_SUCCEEDED"
export const SUBMIT_AREA_ANSWER_FAILED = "SUBMIT_AREA_ANSWER_FAILED"

export const END_EXAM_REQUESTED = "END_EXAM_REQUESTED"
export const END_EXAM_SUCCEEDED = "END_EXAM_SUCCEEDED"
export const END_EXAM_FAILED = "END_EXAM_FAILED"

export const PASS_ANSWER_REQUESTED = "PASS_ANSWER_REQUESTED"
export const PASS_ANSWER_SUCCEEDED = "PASS_ANSWER_SUCCEEDED"
export const PASS_ANSWER_FAILED = "PASS_ANSWER_FAILED"

export const PRACTICE_ANSWER_REQUESTED = "PRACTICE_ANSWER_REQUESTED"
export const PRACTICE_ANSWER_SUCCEEDED = "PRACTICE_ANSWER_SUCCEEDED"
export const PRACTICE_ANSWER_FAILED = "PRACTICE_ANSWER_FAILED"

export const GET_TIME_LEFT_REQUESTED = "GET_TIME_LEFT_REQUESTED"
export const GET_TIME_LEFT_SUCCEEDED = "GET_TIME_LEFT_SUCCEEDED"
export const GET_TIME_LEFT_FAILED = "GET_TIME_LEFT_FAILED"

export const POST_REVIEW_REQUESTED = "POST_REVIEW_REQUESTED"
export const POST_REVIEW_SUCCEEDED = "POST_REVIEW_SUCCEEDED"
export const POST_REVIEW_FAILED = "POST_REVIEW_FAILED"

export const SAVE_TIME = "SAVE_TIME"

export const REMOVE_QUESTION = "REMOVE_QUESTION"
export const PING = "PING"
export const ADD_NEW_TIME_AFTER_ACTIVE="ADD_NEW_TIME_AFTER_ACTIVE"

export const VDO_CIPHER="VDO_CIPHER"
export const VDO_CIPHER_DETAIL_SUCCESS="VDO_CIPHER_DETAIL_SUCCESS"
export const VDO_CIPHER_DETAIL_FAILED="VDO_CIPHER_DETAIL_FAILED"

export const CEREBRY_PRACTICE_DETAIL="CEREBRY_PRACTICE_DETAIL"
export const CEREBRY_PRACTICE_DETAIL_SUCCESS="CEREBRY_PRACTICE_DETAIL_SUCCESS"
export const CEREBRY_PRACTICE_DETAIL_FAILED="CEREBRY_PRACTICE_DETAIL_FAILED"

export const initApp = () => {
  return {
    type: INIT_APP,
  };
};

export const getInfo = testId => {
  return {
    type: GET_INFO_REQUESTED,
    testId
  }
}



export const fetchQuestion = (qIndex,testId) => {
  return {
    type: FETCH_QUESTION_REQUESTED,
    qIndex,
    testId
  }
}

export const getQuestionStatus = () => {
  return {
    type: GET_STATUS_REQUESTED
  }
}

export const saveAnswer = (qId, ans, review) => {
  return {
    type: SAVE_ANSWER,
    qId,
    ans,
    review
  }
}

export const savePractiveAnswer = (qId, ans) => {
  return {
    type: SAVE_PRACTICE_ANSWER,
    qId,
    ans
  }
}

export const saveCrossout = (qId, ans, ansCrossed, userResp) => {
  return {
    type: SAVE_CROSSOUT,
    qId,
    ans,
    ansCrossed,
    userResp,
  }
}

export const submitAreaAnswer = (review) => {
  return {
    type: SUBMIT_AREA_ANSWER_REQUESTED,
    review
  }
}

export const endPauseExam = (timeLeft, endTest) => {
  return {
    type: END_EXAM_REQUESTED,
    timeLeft,
    endTest
  }
}

export const passAnswer = timeElapsed => {
  return {
    type: PASS_ANSWER_REQUESTED,
    timeElapsed
  }
}

export const practiceAnswer = timeElapsed => {
  return {
    type: PRACTICE_ANSWER_REQUESTED,
    timeElapsed
  }
}

export const getTimeLeft = (testId) => {
  return {
    type: GET_TIME_LEFT_REQUESTED,
    testId
  }
}

export const saveTime = (timeElapsed, qIndex) => {
  return {
    type: SAVE_TIME,
    timeElapsed,
    qIndex
  }
}

export const postReview = (reviewQues, qId) => {
  return {
    type: POST_REVIEW_REQUESTED,
    reviewQues,
    qId,
  }
}

export const INIT_SCORE_CARD_APP = "INIT_SCORE_CARD_APP";

export const GET_SCORE_CARD_DETAILS_REQUESTED =
  "GET_SCORE_CARD_DETAILS_REQUESTED";
export const GET_SCORE_CARD_DETAILS_SUCCEEDED =
  "GET_SCORE_CARD_DETAILS_SUCCEEDED";
export const GET_SCORE_CARD_DETAILS_FAILED = "GET_SCORE_CARD_DETAILS_FAILED";

export const GET_SELECTED_QUESTION_DETAILS_REQUESTED =
  "GET_SELECTED_QUESTION_DETAILS_REQUESTED";
export const GET_SELECTED_QUESTION_DETAILS_SUCCEEDED =
  "GET_SELECTED_QUESTION_DETAILS_SUCCEEDED";
export const GET_SELECTED_QUESTION_DETAILS_FAILED =
  "GET_SELECTED_QUESTION_DETAILS_FAILED";

export const SET_FILTERED_QUESTIONS = "SET_FILTERED_QUESTIONS"
export const FETCH_QUESTION_RESET = "FETCH_QUESTION_RESET"
export const SET_FILTERS = "SET_FILTERS"

export const FETCH_FILTERED_QUESTION_REQUESTED = "FETCH_FILTERED_QUESTION_REQUESTED"
export const FETCH_FILTERED_QUESTION_SUCCEEDED = "FETCH_FILTERED_QUESTION_SUCCEEDED"
export const FETCH_FILTERED_QUESTION_FAILED = "FETCH_FILTERED_QUESTION_FAILED"

export const initScoreCardApp = () => {
    return {
      type: INIT_SCORE_CARD_APP,
    };
};

export const scoreCardDetails = (testid) => {
  return {
    type: GET_SCORE_CARD_DETAILS_REQUESTED,
    testid,
  };
};

export const selectedQuestionDetails = (qid,testId) => {
  return {
    type: GET_SELECTED_QUESTION_DETAILS_REQUESTED,
    qid,
    testId
  };
};

export const setFilteredQuestionsList = (data) => {
  return {
    type: SET_FILTERED_QUESTIONS,
    data,
  }
}

export const resetSelectedQuestion = () => {
  return {
    type: FETCH_QUESTION_RESET
  }
}

export const setFilters = (key, data) => {
  return {
    type: SET_FILTERS,
    key,
    data
  }
}

export const fetchFilteredQuestion = (qIndex,testId) => {
  return {
    type: FETCH_FILTERED_QUESTION_REQUESTED,
    qIndex,
    testId
  }
}

export const removeMessage = selectedId => {
  return {
    type: REMOVE_QUESTION,
    selectedId
  }
}

export const Ping = testId => {
  return {
    type: PING,
    testId
  }
}

export const getVdoCipher = id => {
  return {
    type: VDO_CIPHER,
    id
  }
}

export const getCerebry_practice_detail = id => {
  return {
    type: CEREBRY_PRACTICE_DETAIL,
    id
  }
}