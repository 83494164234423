/**
 * @format
 */
export const randomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

export const once = (fn, context) => {
  var result

  return function () {
    if (fn) {
      result = fn.apply(context || this, arguments)
      fn = null
    }

    return result
  }
}

export const userNameSanitizer = /[^a-z0-9@áéíóúñü \.,_-]/gim

export const sanitizeString = (str, regex = userNameSanitizer) => {
  str = str.replace(regex, '')
  return str.trim()
}

export const b64DecodeUnicode = (str) => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
}

export const convertToRoman = (num) => {
  let digits = String(+num).split('')
  const key = [
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ]
  let romanNum = ''
  let i = 3
  while (i--) romanNum = (key[+digits.pop() + i * 10] || '') + romanNum
  return Array(+digits.join('') + 1).join('M') + romanNum
}

const convertStringToArray = (str) => {
  var i = 0;
  function main() {
    var arr = [];
    var startIndex = i;
    function addWord() {
      if (i-1 > startIndex) {
        arr.push(str.slice(startIndex, i-1));
      }
    }
    while (i < str.length) {
      switch(str[i++]) {
        case ",":
          addWord();
          startIndex = i;
          continue;
        case "[":
          arr.push(main());
          startIndex = i;
          continue;
        case "]":
          addWord();
          return arr;
      }
    }
    addWord();
    return arr;
  }
  return main()[0];
}

export const parseQuestionResponse = userInput => {
    let inputString = userInput && userInput.replace(/\\\\/g, "\\");
    return convertStringToArray(inputString)
}

export const classInfo  = {
    day: ['Mon','Tue','Wed','Thu','Fri'],
    months: ["Jan ", "Feb ", "March ", "April ", "May ", "June ", "July ", "Aug ", "Sept ", "Oct ", "Nov ", "Dec "]
}



export function convertTeacherLanguageToLanguage(obj) {
  obj.language = obj.teacherLanguage;
  delete obj.teacherLanguage;
  return obj;
}

import { useEffect } from 'preact/hooks';

const useDetectOutSideClick = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  );
};

export default useDetectOutSideClick;