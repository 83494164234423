import Immutable from 'seamless-immutable';
import {
  GET_INFO_SUCCEEDED, GET_INFO_FAILED,
  FETCH_QUESTION_REQUESTED, FETCH_QUESTION_SUCCEEDED, FETCH_QUESTION_FAILED,
  GET_STATUS_SUCCEEDED, GET_STATUS_FAILED,
  SAVE_ANSWER_SUCCEEDED,
  SAVE_CROSSOUT,
  SUBMIT_AREA_ANSWER_SUCCEEDED, SUBMIT_AREA_ANSWER_FAILED,
  PASS_ANSWER_SUCCEEDED, PASS_ANSWER_FAILED,
  PRACTICE_ANSWER_SUCCEEDED, PRACTICE_ANSWER_FAILED,
  GET_TIME_LEFT_SUCCEEDED, GET_TIME_LEFT_FAILED,
  SAVE_TIME,
  SAVE_PRACTICE_ANSWER,
  GET_SCORE_CARD_DETAILS_SUCCEEDED,
  GET_SELECTED_QUESTION_DETAILS_SUCCEEDED,
  SET_FILTERED_QUESTIONS,
  FETCH_QUESTION_RESET,
  SET_FILTERS,
  FETCH_FILTERED_QUESTION_REQUESTED,
  FETCH_FILTERED_QUESTION_SUCCEEDED,
  REMOVE_QUESTION,
  ADD_NEW_TIME_AFTER_ACTIVE,
  VDO_CIPHER_DETAIL_SUCCESS,
  CEREBRY_PRACTICE_DETAIL_SUCCESS,
  VDO_CIPHER,
  VDO_CIPHER_DETAIL_FAILED
} from './action';

import { b64DecodeUnicode, convertTeacherLanguageToLanguage, parseQuestionResponse } from './utils';

export const initialState = Immutable({
  testId: undefined,
  testInfo: {},
  questionList: undefined,
  filteredQuestionList: undefined,
  questionView: {},
  selectedQuestion: undefined,
  questionCategories: undefined,
  vdoCipherDetail: null,
  vdoCipherDetailLoading: false,
  cerebryPracticeDetail:null,
  filters: {
    status: { label: "All", value: "all" },
    categories: [],
    areas: [],
    topics: []
  }
});

export default (state = initialState, action) => {
  if (action.resp && action.resp.hasOwnProperty('tokenRefreshed')) {
    const tokenRefreshed = action.resp.tokenRefreshed;
    state = state.merge({
      userSession: state.userSession.merge({
        tokenRefreshed: true,
      }),
    });
  }
  switch (action.type) {
    case GET_INFO_SUCCEEDED:
      return state.setIn(["testInfo"], action.data)
        .setIn(["questionList"], action.data.questionList)
        .setIn(["filteredQuestionList"], action.data.questionList)
        .setIn(["questionCategories"], action.data.questionCategories)
        .setIn(["testId"], action.testId)
    case SAVE_TIME:
      return state.setIn(["timeList", action.qIndex, "timeElapsed"], action.timeElapsed)
    case FETCH_QUESTION_REQUESTED:
    case FETCH_FILTERED_QUESTION_REQUESTED:
      return state.setIn(["selectedQuestion"], undefined)
    case FETCH_QUESTION_RESET:
      return state.setIn(["questionView"], {})
        .setIn(["selectedQuestion"], undefined)
    case FETCH_QUESTION_SUCCEEDED:
    case FETCH_FILTERED_QUESTION_SUCCEEDED:
      return state.setIn(["questionView", action.qId], action.data)
        .setIn(["selectedQuestion", "id"], action.qId)
        .setIn(["selectedQuestion", "qIndex"], action.qIndex)
    case GET_STATUS_SUCCEEDED:
      return state.setIn(["questionList"], action.data)
    case SAVE_ANSWER_SUCCEEDED:
      return state.setIn(["questionView", action.qId, "userResponse"], action.ans)
    case SAVE_PRACTICE_ANSWER:
      return state.setIn(["questionView", action.qId, "userResponse"], action.ans)
    case SAVE_CROSSOUT:
      if (action.ansCrossed) return state.setIn(["questionView", action.qId, "crossOutOptionsList"], action.ans)
        .setIn(["questionView", action.qId, "userResponse"], action.userResp)
      return state.setIn(["questionView", action.qId, "crossOutOptionsList"], action.ans)
    case PASS_ANSWER_SUCCEEDED:
      return state.setIn(["questionView", action.qId, "correctResponse"], action.data.correctResponse)
        .setIn(["questionView", action.qId, "status"], action.data.status)
    case PRACTICE_ANSWER_SUCCEEDED:
      return state.setIn(["questionView", action.qId, "correctResponse"], action.data.correctResponse)
        .setIn(["questionView", action.qId, "status"], action.data.status)
        .setIn(["questionView", action.qId, "message"], action.data.message)
    case GET_TIME_LEFT_SUCCEEDED:
      return state.setIn(["timeList"], action.data)

    case GET_SCORE_CARD_DETAILS_SUCCEEDED:
      return state
        .setIn(["testInfo"], action.data)
        .setIn(["questionList"], action.data.questionList)
        .setIn(["testId"], action.testId);

    case GET_SELECTED_QUESTION_DETAILS_SUCCEEDED:
      return state.setIn(["selectedQuestion"], action.data);
    case SET_FILTERED_QUESTIONS:
      return state.setIn(["filteredQuestionList"], action.data);
    case VDO_CIPHER:
      return state.setIn(["vdoCipherDetailLoading"], true);
    case VDO_CIPHER_DETAIL_SUCCESS:
      return state.setIn(["vdoCipherDetail"], action.data)
      .setIn(["vdoCipherDetailLoading"], false);
    case VDO_CIPHER_DETAIL_FAILED:
      return state.setIn(["vdoCipherDetailLoading"], false);
    case CEREBRY_PRACTICE_DETAIL_SUCCESS:
      return state.setIn(["cerebryPracticeDetail"], action.data);
    case SET_FILTERS:
      return state
        .setIn(["filters", action.key], action.data)
        .setIn(["filters", action.key], action.data)
        .setIn(["filters", action.key], action.data);

    case REMOVE_QUESTION:
      return state.setIn(["questionView", action.selectedId, "message"], undefined);
    case ADD_NEW_TIME_AFTER_ACTIVE:
      return state.updateIn(["testInfo"], (testInfo) => ({
        ...testInfo,
        timeLeft: action.data // Assuming action.data has the timeLeft property
      }));
    default:
      return state;
  }
};
