import { createSelector } from "reselect";

import Immutable from "seamless-immutable";

export const selectApp = (state) => state;

export const mkSelectQuestionList = () => createSelector(
	selectApp,
	(appState) => appState.questionList
)

export const mkSelectFilteredQuestionList = () => createSelector(
	selectApp,
	(appState) => appState.filteredQuestionList
)

export const mkSelectMode = () => createSelector(
	selectApp,
	(appState) => appState.testInfo.modeType
)

export const mkSelectTestId = () => createSelector(
	selectApp,
	(appState) => appState.testId
)

export const mkSelectQues = () => createSelector(
	selectApp,
	(appState) => appState.selectedQuestion
)

export const mkSelectQuesView = () => createSelector(
	selectApp,
	(appState) => appState.questionView
)