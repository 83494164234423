import apisauce from "apisauce";
import { once } from "./utils";
const BASE_URL = "https://bete.imsindia.com/";

const create = (baseURL = BASE_URL) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 10000,
  });

  const setAccessToken = (token) => {
    return api.setHeader("Authorization", token);
  };

  const removeAccessToken = () => {
    delete api.headers["Authorization"];
  };

  const retryApiSauce = (response) => {
    let methodType = response.config.method.toUpperCase();
    switch (methodType) {
      case 'GET':
        return api.get(response.config.url, response.config.params);
      case 'POST':
        return api.post(response.config.url, response.config.data);
      case 'PUT':
        return api.put(response.config.url, response.config.data);
      case 'DELETE':
        return api.delete(response.config.url, response.config.params);
      default:
        return api.any(response.config);
    }
  }

  const check401 = once((refreshToken) => {
    api.addAsyncResponseTransform(async (response) => {
      let status = response.status || response.data.status;
      let apiUrl = response.url || response.config.url;
      const apiImmuned401 = `api/v2/users/refresh`;
      if (status == 401 && apiUrl.indexOf(apiImmuned401) < 0) {
        setAccessToken(refreshToken);
        const updatedJwtTokenResponse = await refreshUser();
        if (updatedJwtTokenResponse.status != 401 && updatedJwtTokenResponse.data && updatedJwtTokenResponse.data.jwt) {
          let newJwtToken = "jwt " + updatedJwtTokenResponse.data.jwt;
          setAccessToken(newJwtToken);
          saveJWT(JWT_TOKEN, updatedJwtTokenResponse.data.jwt);
          const updatedResponse = await retryApiSauce(response);
          if (updatedResponse && updatedResponse.status != 401 && updatedResponse.data) {
            //this line is not mutating the response, so setting the corresponding values alone.
            //response = updatedResponse;
            response.data = updatedResponse.data;
            response.status = updatedResponse.status;
          }
        } else {
          response.tokenRefreshed = true;
        }
      }
    });
  });

  const getInfo = (testId, cerebry_query) => {
    if (cerebry_query == undefined) {
      cerebry_query = ""
    }
    return api.get(`/api/v2/users/area_test_player_assessment/${testId}/info/${cerebry_query}`)
  }

  const getPracticeQuestion = (qid, cerebry_query, testId) => {
    if (cerebry_query == undefined) {
      cerebry_query = ""
    }
    if (testId == undefined) {
      testId = ""
    }
    return api.get(`/api/v2/users/practice_test_player_assessment/${qid}/question/?test_id=${testId}${cerebry_query}`)
  }

  const getAreaQuestion = (qid, cerebry_query, testId, area_cerebry_query) => {
    if (area_cerebry_query == undefined) {
      area_cerebry_query = ""
    }
    return api.get(`/api/v2/users/area_test_player_assessment/${qid}/question/${area_cerebry_query}`)
  }

  const getPracticeStatus = (testid, cerebry_query) => {
    if (cerebry_query == undefined) {
      cerebry_query = ""
    }
    return api.get(`/api/v2/users/area_practice_player_assessment/${testid}/questions-progress/${cerebry_query}`)
    // return {
    //   status: 200,
    //   data: [{"id": 1,"status": 0},{"id": 2,"status": 1},{"id": 3,"status": 2},{"id": 4,"status": 2},{"id": 5,"status": 1},{"id": 6,"status": 0},{"id": 7,"status": 0},{"id": 8,"status": 0},{"id": 9,"status": 0},{"id": 10,"status": 0}]
    // }
  }

  // const getAreaStatus = testid => {
  //   return api.get(`/api/v2/users/area_test_player_assessment/${testid}/questions-progress/`)
  const getAreaStatus = (testid, cerebry_query) => {
    if (cerebry_query == undefined) {
      cerebry_query = ""
    }
    return api.get(`/api/v2/users/area_test_player_assessment/${testid}/questions-progress/${cerebry_query}`)
    // return {
    //   status: 200,
    //   data: [{"id": 1,"status": 1},{"id": 2,"status": 0},{"id": 3,"status": 0},{"id": 4,"status": 2},{"id": 5,"status": 0},{"id": 6,"status": 0},{"id": 7,"status": 0},{"id": 8,"status": 0},{"id": 9,"status": 0},{"id": 10,"status": 0}]
    // }
  }

  // const submitAreaNext = payload => {
  //   return api.post(`/api/v2/users/area_test_player_assessment/${payload.question_id}/ims-submit-answer/`, payload)
  const submitAreaNext = (payload, cerebry_query) => {
    if (cerebry_query == undefined) {
      cerebry_query = ""
    }
    return api.post(`/api/v2/users/area_test_player_assessment/${payload.question_id}/ims-submit-answer/${cerebry_query}`, payload)
  }

  const endPauseExam = (payload, cerebry_query) => {
    return api.post(`/api/v2/users/area_test_player_assessment/${payload.test_id}/end/${cerebry_query}`, payload)
  }

  const endPracticePauseExam = (payload, cerebry_query) => {
    return api.post(`/api/v2/users/practice_test_player_assessment/${payload.test_id}/end/${cerebry_query}`, payload)
  }

  // const submitPractice = payload => {
  //   return api.post(`/api/v2/users/practice_test_player_assessment/${payload.question_id}/ims-submit-answer/`, payload)
  const submitPractice = (payload, cerebry_query) => {
    if (cerebry_query == undefined) {
      cerebry_query = ""
    }
    return api.post(`/api/v2/users/practice_test_player_assessment/${payload.question_id}/ims-submit-answer/${cerebry_query}`, payload)
    // return {
    //   status: 200,
    //   data: {
    //     status: 1,
    //     correct_response: 0
    //   }
    // }
  }

  // const getTimeLeft = testid => {
  //   return api.get(`/api/v2/users/practice_test_player_assessment/${testid}/time-left/`)

  const getTimeLeft = (testid, cerebry_query) => {
    return api.get(`/api/v2/users/practice_test_player_assessment/${testid}/time-left/${cerebry_query}`)
    // return {
    //   status: 200,
    //   data: [{"id": 1,"time_elapsed": 0},{"id": 2,"time_elapsed": 60},{"id": 3,"time_elapsed": 600},{"id": 4,"time_elapsed": 2400},{"id": 5,"time_elapsed": 0},{"id": 6,"time_elapsed": 0},{"id": 7,"time_elapsed": 0},{"id": 8,"time_elapsed": 0},{"id": 9,"time_elapsed": 0},{"id": 10,"time_elapsed": 0}]
    // }
  }

  // const getScoreCardDetails = (testid) => {
  //   return api.get(`/api/v2/users/test/${testid}/score-card/`);
  const getScoreCardDetails = (testId, cerebry_query) => {
    if (cerebry_query == undefined) {
      cerebry_query = ""
    }
    return api.get(`/api/v2/users/test/${testId}/score-card/${cerebry_query}`);
  };


  const getCipherDetails = (payload) => {
    return api.post(`/api/v2/users/vdo-cipher-details/`, payload);
  };

  const getCerebryPractiseDetails = (payload) => {
    return api.post(`/api/v2/users/practice-link-button-details/`, payload);
  };

  // const getSelectedQuestionDetails = (questionid,testId) => {
  const getSelectedQuestionDetails = (questionid, testId, cerebry_query) => {
    if (testId == undefined) {
      testId = ""
    }
    // return api.get(`/api/v2/users/question/${questionid}/score-card-question/?test_id=${testId}`);
    if (cerebry_query == undefined) {
      cerebry_query = ""
    }
    return api.get(`/api/v2/users/question/${questionid}/score-card-question/?test_id=${testId}${cerebry_query}`);
  };

  const getPingAPI = (testid) => {
    return api.get(`/api/v2/users/exam/${testid}/ping/`);
  };

  return {
    setAccessToken,
    removeAccessToken,
    check401,
    getInfo,
    getPracticeQuestion,
    getAreaQuestion,
    getPracticeStatus,
    getAreaStatus,
    submitAreaNext,
    endPauseExam,
    submitPractice,
    endPracticePauseExam,
    getTimeLeft,
    getScoreCardDetails,
    getSelectedQuestionDetails,
    getPingAPI,
    getCipherDetails,
    getCerebryPractiseDetails
  };
};

export default create();
